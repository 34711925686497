import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ScTable from '../components/scinceTable';

function Science() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title='About'
      />

      <section className='flex flex-col items-center '>
        <h1 className=' text-center text-lg uppercase tracking-wider text-green-800 font-bold mb-4'>
          НАУЧНО-МЕТОДИЧЕСКОЕ СОПРОВОЖДЕНИЕ ИННОВАЦИОННОЙ ДЕЯТЕЛЬНОСТИ
        </h1>

        <ScTable />
      </section>
    </Layout>
  );
}

export default Science;
