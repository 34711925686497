import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 10px;
  overflow-x: scroll;
`;

const StyledTable = styled.table`
  display: grid;
  border-collapse: collapse;

  min-width: 100%;
  grid-template-columns:
    minmax(50px, 0.1fr)
    minmax(200px, 2fr);
`;
const StyledThead = styled.thead`
  display: contents;
`;
const StyledTh = styled.th`
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background: #5e9952;
  text-align: center;
  font-weight: normal;
  font-size: 0.9rem;
  color: white;
  border-right: 1px solid #000;
  &:first-child {
    border-left: 1px solid #000;
  }
`;
const StyledTbody = styled.tbody`
  display: contents;
`;

const StyledTr = styled.tr`
  text-align: center;
  display: contents;

  &:nth-child(even) td {
    background: #f8f6ff;
  }
`;
const StyledTd = styled.td`
  border-right: 1px solid #000;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-bottom: 1px solid #000;
  padding: 15px;
  overflow: hidden;

  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
  :first-child {
    border-left: 1px solid #000;
  }
`;

const data = [
  {
    number: "1",
    second:
      "Образовательные организации на научно-методическом сопровождении и результативность",
  },
  {
    number: "2",
    second: "Содержание научно-методического сопровождения",
  },
  {
    number: "3",
    second: "Инновационный образовательный проект",
  },
];

const ScTable = () => {
  const renderedPods = data.map(({ number, second }) => (
    <StyledTr key={number}>
      <StyledTd className="">{number}</StyledTd>
      <StyledTd>{second}</StyledTd>
    </StyledTr>
  ));

  return (
    <Container>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            <StyledTh>№</StyledTh>
            <StyledTh>НАИМЕНОВАНИЕ</StyledTh>
          </StyledTr>
        </StyledThead>
        <StyledTbody>{renderedPods}</StyledTbody>
      </StyledTable>
    </Container>
  );
};

export default ScTable;
